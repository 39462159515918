import { createStore as _createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { responsiveStoreEnhancer } from 'redux-responsive'

export default function createStore(initialState) {
  const middleware = [thunk]

  const devtoolsOptions = {
    actionsBlacklist: [],
    name: `peachy-theme`,
  }

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devtoolsOptions)
      : compose

  const finalCreateStore = composeEnhancers(
    responsiveStoreEnhancer,
    applyMiddleware(...middleware)
  )(_createStore)

  const reducer = require('./reducers.js')
  const store = finalCreateStore(reducer.default, initialState)

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('./reducers/main-reducer', () => {
  //     store.replaceReducer(require('./reducers/main-reducer').default);
  //   });
  // }

  if (process.env.NODE_ENV !== 'production') {
    window.store = store
  }

  return store
}
