// Import everything from autoload folder
import './autoload/_bootstrap-toolkit.js'; import './autoload/_bootstrap.js'; import './autoload/_lazyload.js'; import './autoload/_object-fit-images.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// Import local dependencies
import 'jquery-match-height'
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import 'slick-carousel'

import Navigation from './inc/navigation'
import Common from './inc/common'

$(document).ready(() => {
  Navigation.init()
  Common.init()
})

$(window).load(() => {
  Common.onLoad()
})
