import cssVars from 'css-vars-ponyfill'
import { getCSSVars } from '../util/functional'
import Nav from './navigation'

export default {
  html: $('html'),
  body: $('body'),
  htmlFixedState: false,
  scrollTopState: 0,
  init() {
    this.setCSSVars()
    this.onResize()

    $(
      'a[href^="#"]:not([href="#"],[href^="#fancy-"],[href^="#collapse-"],[href^="#tabs-"],[role="tab"],[data-vc-accordion])'
    ).on('click', e => {
      this.smoothScroll($(e.currentTarget).attr('href'), e)
    })
    if (window.location.hash && $(window.location.hash).length) {
      this.smoothScroll(window.location.hash)
    }

    this.peopleCollapseMobile()

    this.sliderInit()
    this.articlesSliderInit()
    this.stepsSliderInit()

    const purposeSliders = $('.js-purpose-slider')
    if (purposeSliders.length) {
      this.mobileSliderInit(
        purposeSliders,
        {
          dots: true,
          focusOnSelect: true,
          initialSlide: 1,
          responsive: [
            {
              breakpoint: parseInt(getCSSVars('--bp-sm')) - 1,
              settings: {
                centerMode: true,
                centerPadding: '22.5%',
              },
            },
          ],
        },
        'md'
      )
    }

    const peopleSliders = $('.js-people-slider')
    if (peopleSliders.length) {
      this.mobileSliderInit(
        peopleSliders,
        {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 4,
        },
        'lg'
      )
    }

    const gallatySliders = $('.js-gallary-slider')
    if (gallatySliders.length) {
      gallatySliders.each((index, el) => {
        const $slider = $(el)
        if ($('.gallary__item', $slider).length > 4) {
          this.mobileSliderInit($slider, {
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            focusOnSelect: true,
            swipeToSlide: true,
          })
        }
      })
    }
  },
  setCSSVars() {
    cssVars({
      variables: {
        '--body-width': document.body.clientWidth + 'px',
      },
    })
  },
  htmlFixed() {
    const scrollTop = $(document).scrollTop()
    this.html.toggleClass('is-fixed')
    this.htmlFixedState = !this.htmlFixedState
    if (this.htmlFixedState) {
      this.scrollTopState = scrollTop
      this.body.css({
        top: -this.scrollTopState,
      })
    } else {
      this.body.css({
        top: '',
      })
      $('html, body').animate({ scrollTop: this.scrollTopState }, 0)
    }
  },
  peopleCollapseMobile() {
    $('body').on('click', '.js-people-collapse', event => {
      event.preventDefault()
      const $el = $(event.currentTarget)
      $el
        .toggleClass('active')
        .closest('.js-people-wrapper')
        .find('.js-people-item:nth-child(n+5)')
        .slideToggle()
    })
  },
  mobileSliderInit($slider, args = {}, bp = 'sm') {
    const viewport = window.bootstrapToolkit
    $slider.each(function() {
      const slider = $(this)
      const sliderArgs = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        nextArrow:
          '<button type="button" class="slick-next">' +
          '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">' +
          '<path d="M1 1L14.5 15L1 27" stroke="currentColor" stroke-width="2"/>' +
          '</svg></button>',
        prevArrow:
          '<button type="button" class="slick-prev">' +
          '<svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">' +
          '<path d="M15.5 27L2 13L15.5 1" stroke="currentColor" stroke-width="2"/>' +
          '</svg></button>',
        ...args,
      }

      const sliderCheck = () => {
        if (viewport.is(`<${bp}`)) {
          if (!slider.hasClass('slick-initialized')) {
            slider.slick(sliderArgs)
          }
        } else {
          if (slider.hasClass('slick-initialized')) {
            slider.slick('unslick')
          }
        }
      }

      sliderCheck()

      $(window).resize(
        viewport.changed(function() {
          sliderCheck()
        })
      )
    })
  },
  sliderInit() {
    const $sliders = $('.js-slider')
    if ($sliders.length) {
      $sliders.each(function() {
        const slider = $(this)

        const sliderArgs = {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        }

        slider.slick(sliderArgs)
      })
    }
  },
  articlesSliderInit() {
    const $sliders = $('.js-articles-slider')
    if ($sliders.length) {
      $sliders.each(function() {
        const slider = $(this)

        const sliderArgs = {
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          arrows: false,
          // swipeToSlide: true,
          responsive: [
            {
              breakpoint: parseInt(getCSSVars('--bp-lg')) - 1,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: parseInt(getCSSVars('--bp-md')) - 1,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        }

        slider.on('setPosition', () => {
          $('.js-article-heading', slider).matchHeight()
        })
        slider.slick(sliderArgs)
      })
    }
  },
  stepsSliderInit() {
    const $sliders = $('.js-steps-slider')
    if ($sliders.length) {
      $sliders.each(function() {
        const slider = $(this)

        const sliderArgs = {
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          arrows: false,
          swipeToSlide: true,
          focusOnSelect: true,
        }

        slider
          .closest('.js-steps-slider-wrapper')
          .on('click', '.js-steps-slider-link', e => {
            const areaControls = $(e.currentTarget).attr('aria-controls')
            const slideIndex = $(e.currentTarget).data('slide-index')
            $(e.currentTarget)
              .closest('.js-steps-slider-wrapper')
              .find('.js-steps-slider-link.active')
              .removeClass('active')
              .attr('aria-selected', 'false')
              .end()
              .find(
                `.js-steps-slider-link:not(.active)[aria-controls="${areaControls}"]`
              )
              .tab('show')

            setTimeout(() => {
              slider.slick('slickGoTo', Math.round(parseInt(slideIndex) / 3))
            }, 50)

            // $('html, body').animate({ scrollTop: targetSlider.offset().top }, 0)
          })

        slider.slick(sliderArgs)
      })
    }
  },
  smoothScroll(link, e) {
    if (
      link &&
      link.indexOf('#') > 0 &&
      link.split('#')[0].indexOf(location.origin + location.pathname) !== 0
    ) {
      return false
    }
    if (e) e.preventDefault()
    var hash = false
    if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') >= 0) {
      hash = '#' + link.split('#')[1]
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }
    if (hash) {
      if (this.htmlFixedState) {
        Nav.menuToggle()
      }
      $('html, body').animate(
        {
          scrollTop: $(hash).offset().top,
        },
        600
      )
      history.replaceState(null, null, hash)
    }
    $(window).trigger('hashchange')
  },
  onResize() {
    $(window).resize(() => {
      this.setCSSVars()
    })
  },
  onLoad() {},
}
