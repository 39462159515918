import Common from './common'
import { getCSSVars } from '../util/functional'

export default {
  nav: $('.js-nav-primary'),
  menu: $('.js-header-menu'),
  navToggler: $('.js-nav-primary-toggler'),
  submenuToggler: $('.js-submenu-toggler'),
  submenuClass: '.js-sub-menu',
  viewport: window.bootstrapToolkit,
  bpMobile: getCSSVars('--bp-mobile-menu').replace(/ /g, ''),
  timeout: null,
  init() {
    this.submenuPosition()
    this.mobileMenuInit()
    this.onResize()
  },
  submenuPosition() {
    const windowWidth = $(window).width()
    $('.js-sub-menu', this.nav).each((index, element) => {
      const $el = $(element)
      $el.removeClass('open-left')
      const elPosition = $el.offset().left + $el.outerWidth()
      if (elPosition > windowWidth) {
        if (!$el.hasClass('open-left')) {
          $el.addClass('open-left')
        }
      }
    })
  },
  menuToggle() {
    this.navToggler.toggleClass('active')
    this.nav.toggleClass('is-opened')
    Common.htmlFixed()
  },
  mobileMenuInit() {
    this.navToggler.on('click', () => {
      this.menuToggle()
    })
    this.submenuToggler.on('click', event => {
      const $el = $(event.currentTarget)
      const $toggler = $('.submenu-toggler', $(event.currentTarget))
      if ($toggler.is(event.target)) {
        event.preventDefault()
        event.stopPropagation()
        $toggler.toggleClass('is-active')
        $el.next(this.submenuClass).slideToggle(200)
      }
    })

    $(document).mouseup(e => {
      if (
        !this.nav.is(e.target) &&
        this.nav.has(e.target).length === 0 &&
        !this.navToggler.is(e.target) &&
        this.navToggler.has(e.target).length === 0 &&
        this.nav.hasClass('is-opened')
      ) {
        this.menuToggle()
      }
    })
  },
  onResize() {
    $(window).resize(
      this.viewport.changed(() => {
        if (
          Common.html.hasClass('is-fixed') &&
          this.viewport.is(`>${this.bpMobile}`)
        ) {
          this.menuToggle()
          $('.submenu-toggler.is-active', this.submenuToggler).removeClass(
            'is-active'
          )
          $(this.submenuClass).attr('style', '')
        }
        this.submenuPosition()
      })
    )
  },
}
